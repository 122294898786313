import React from 'react';
import { graphql } from "gatsby"
import DateFormatter from '../components/date-formatter'
import { RichTextRenderer } from '../components/rich-text-renderer'
import Layout from "../components/layout"
import Seo from "../components/seo"
import CarouselSlider from "../components/carousel-slider"

const NewsArticleTemplate = ({ data, location }) => {
  const news = data.webiny.getAlphaBetasNews.data
  const newsData = data.webiny.listAlphaBetasNews.data
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data.webiny

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={news.title || siteTitle}
        description={news.description || news.excerpt}
      />
        <div className="newsPage inline-block w-full pt-32">
          <div className="bg-ab-blue-100 w-full">
            <div className="mx-auto p-5 m-0 w-full md:w-1/2">
            <div className="font-CobeSemiBold text-md pb-5 pt-0 md:py-10"><a href="/" className="">Home</a> / News / <span className="text-ab-yellow-600">{news.title}</span></div>
              <div className="my-10">
                  <img 
                  src={news.heroImage} 
                  className="w-full rounded-lg" 
                  />
              </div>
              <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 md:py-10 lg:px-0">
                <div className="text-center">
                  <h1 className="text-center md:text-left text-4xl md:text-5xl font-GodaBold uppercase mb-5 text-ab-yellow-600">{news.title}</h1>
                </div>
                <div className="font-md font-CobeSemiBold mb-5">Published: <DateFormatter dateString={news.datePublished} /></div>
                <div className="newsPageBody">
                  {news.body ? 
                    <RichTextRenderer content={news.body} />
                  : null }
                </div>
              </div>
            </div>
            
           

          <div className="bg-bodyPattern w-full py-10 md:py-20 px-10">
            <div className="block max-w-7xl mx-auto">
              <h2 className="text-center md:text-left w-full md:w-8/12 lg:w-full text-4xl md:text-5xl font-GodaBold uppercase text-ab-yellow-600">Latest News</h2>
              <div className="w-full md:w-8/12 lg:w-full mb-5 md:mb-10">
                <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">See what's new in the Alpha Betas community!</p>
              </div>
                <CarouselSlider carousel={newsData} slidesPerPage={3} type="newsData" />
            </div>
          </div>


          </div>
        </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query NewsBySlug(
      $slug: String
      $id: ID
      $createdOn: Webiny_DateTime
    ) {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      getAlphaBetasNews(where: {slug: $slug}) {
        data {
          title
          visibility
          slug
          shortDescription
          id
          heroImage
          entryId
          datePublished
          body
          createdOn
        }
      }
      listAlphaBetasNews(sort: datePublished_DESC) {
        data {
          title
          visibility
          slug
          shortDescription
          id
          heroImage
          entryId
          datePublished
          body
        }
      }
      next: listAlphaBetasNews(limit: 1, where: {createdOn_gt: $createdOn, id_not: $id }) {
        data {
          title
          slug
        }
      }
      previous: listAlphaBetasNews(limit: 1, where: {createdOn_lt: $createdOn, id_not: $id }) {
        data {
          title
          slug
        }
      }
    } 
  }
`

export default NewsArticleTemplate;